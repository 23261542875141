<template>
	<div class="animated fadeIn">
		<b-card :title="'Edit Dispatch (' + form.dispatchId + ')'"
			sub-title="Handles the update of existing dispatches for all companies">
			<br />
			<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
			<b-form @submit.stop.prevent="handleSubmit" novalidate>
				<div role="tablist">
					<b-card no-body class="mb-1">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-btn block href="#" v-b-toggle.accordion1 variant="primary"
								class="text-sm-left accordion-title">
								SOURCE AND DESTINATION
							</b-btn>
						</b-card-header>
						<b-collapse v-model="showFirstPane" id="accordion1" accordion="my-accordion" role="tabpanel">
							<b-card-body>
								<b-row class="my-2">
									<b-col sm="12">
										<i class="icon-home"></i>&nbsp;
										<b>SOURCE</b>
									</b-col>
								</b-row>
								<b-row class="my-12">
									<b-col lg="4" md="6" sm="12">
										<b-form-group label="Company:" label-for="Source Company">
											<v-select name="Source Company" class="style-chooser" label="text"
												:options="sourceCompanyOptions" :reduce="(company) => company.value"
												v-model="selSourceCompany" v-validate="'selectRequired'">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
											<span v-show="errors.has('Source Company')" class="help-block">
												{{ errors.first('Source Company') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12">
										<b-form-group label="Storage Location:" label-for="Source Storage Location">
											<v-select name="Source Storage Location" class="style-chooser" label="text"
												:options="sourceLocationOptions" :reduce="(loc) => loc.value"
												v-model="selSourceLocation" v-validate="'selectRequired'">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a location
													</em>
												</template>
											</v-select>
											<span v-show="errors.has('Source Storage Location')" class="help-block">
												{{ errors.first('Source Storage Location') }}
											</span>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row class="my-2">
									<b-col sm="12">
										<i class="icon-home"></i>&nbsp;
										<b>DESTINATION</b>
									</b-col>
								</b-row>
								<b-row class="my-12">
									<b-col lg="4" md="6" sm="12">
										<b-form-group label="Company:" label-for="Destination Company">
											<v-select name="Destination Company" class="style-chooser" label="text"
												:options="destinationCompanyOptions" :reduce="(company) => company.value"
												v-model="selDestinationCompany" v-validate="'selectRequired'">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
											<span v-show="errors.has('Destination Company')" class="help-block">
												{{ errors.first('Destination Company') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12">
										<b-form-group label="Storage Location:" label-for="Destination Storage Location">
											<v-select name="Destination Storage Location" class="style-chooser" label="text"
												:options="destinationLocationOptions" :reduce="(loc) => loc.value"
												v-model="selDestinationLocation" v-validate="'selectRequired'">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a location
													</em>
												</template>
											</v-select>
											<span v-show="errors.has('Destination Storage Location')" class="help-block">
												{{ errors.first('Destination Storage Location') }}
											</span>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row class="my-2">
									<b-col sm="12">
										<i class="icon-info"></i>&nbsp;
										<b>REMARKS</b>
									</b-col>
								</b-row>
								<b-row class="my-12">
									<b-col lg="4" md="6" sm="12">
										<b-form-group label="Notes:" label-for="Notes" description>
											<b-form-textarea name="Notes" type="text" v-model="form.notes" maxlength="200"
												v-validate="{
													required: true,
													regex: /^([A-Za-z0-9\s:;,.!'\/()-_]{1,200})$/,
												}" :rows="3" placeholder="Notes" />
											<span v-show="errors.has('Notes')" class="help-block">
												{{ errors.first('Notes') }}
											</span>
										</b-form-group>
									</b-col>
								</b-row>
							</b-card-body>
						</b-collapse>
					</b-card>

					<b-card no-body class="mb-1">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-btn block href="#" v-b-toggle.accordion3 variant="primary"
								class="text-sm-left accordion-title">
								TRANSPORTATION
							</b-btn>
						</b-card-header>
						<b-collapse id="accordion3" accordion="my-accordion" role="tabpanel">
							<b-card-body>
								<b-row class="my-12">
									<b-col lg="4" md="6" sm="12">
										<b-form-group label="Plate No/CR/OR:" label-for="Plate No">
											<v-select class="style-chooser" name="Plate No" label="text"
												:options="allTransportationsOptions"
												:reduce="(selTransportation) => selTransportation.value"
												v-validate="'selectRequired'" v-model="selTransportation" />
											<span v-show="errors.has('Plate No')" class="help-block">
												{{ errors.first('Plate No') }}
											</span>
										</b-form-group>
									</b-col>

									<b-col lg="4" md="6" sm="12" v-if="!(selTransportation.name === ' - Others - ')">
										<b-form-group label="Company:" label-for="company">
											<b-form-input name="Company" type="text" v-model="selTransportation.company"
												maxlength="50" readonly />
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" v-else>
										<b-form-group label="Company:" label-for="company">
											<b-form-input id="company" name="Company" type="text"
												v-model.trim="form.newTransportationToAdd.company" v-validate="'required'"
												maxlength="50" />
											<span v-show="errors.has('Company')" class="help-block">
												{{ errors.first('Company') }}
											</span>
										</b-form-group>
										<b-form-group label="Plate No/CR/OR" label-for="plateNumber">
											<b-form-input id="plateNumber" name="Plate Number" type="text"
												v-model.trim="form.newTransportationToAdd.plateNo" v-validate="{
													required: true,
													regex: /^[a-zA-Z0-9_ -]{6,12}$/,
												}" maxlength="12" />
											<span v-show="errors.has('Plate Number')" class="help-block">
												{{ errors.first('Plate Number') }}
											</span>
										</b-form-group>
									</b-col>

								</b-row>

								<b-row class="my-12">
									<b-col lg="4" md="6" sm="12">
										<b-form-group label="Driver:" label-for="Driver">
											<v-select class="style-chooser" name="Driver" label="text"
												:options="allDriverOptions" :reduce="(selDriver) => selDriver.value"
												v-validate="'selectRequired'" v-model="selDriver" />
											<span v-show="errors.has('Driver')" class="help-block">
												{{ errors.first('Driver') }}
											</span>
										</b-form-group>

										<b-form-group v-if="selDriver.id != null" label-for="hasCheckingInterval">
											<b-form-checkbox id="hasCheckingInterval" name="hasCheckingInterval"
												v-model="form.transportation.hasCheckingInterval">
												Checking Interval (hrs):
											</b-form-checkbox>
										</b-form-group>

										<b-form-group v-if="selDriver.id != null" label-for="checkingInterval">
											<b-form-input id="checkingInterval" name="Checking Interval" type="number"
												v-model="form.transportation.checkingInterval"
												v-validate="`${form.transportation.hasCheckingInterval ? 'required|min_value:1|max_value:24' : ''}`"
												min="1" max="24" step="1" onwheel="this.blur()"
												placeholder="Checking Interval"
												:readonly="!form.transportation.hasCheckingInterval" />
											<span v-show="errors.has('Checking Interval')" class="help-block">
												{{ errors.first('Checking Interval') }}
											</span>
										</b-form-group>

										<b-form-group v-if="selDriver.name === ' - Others - ' ? true : false"
											label="Drivers License:" label-for="drivers-license"
											description="*32-bit PNG, 1000px by 1000px, up to 1 MB">
											<b-form-file id="drivers-license" placeholder="Choose image"
												ref="dirvers-lincense-file-input" accept="image/png"
												@change="onSelectDriversLicense($event)">
												<template slot="file-name" slot-scope="{ names }">
													<b-badge variant="dark">{{ names[0] }}</b-badge>
													<b-badge v-if="names.length > 1" variant="dark" class="ml-1">
														+ {{ names.length - 1 }} More files
													</b-badge>
												</template>
											</b-form-file>
										</b-form-group>

										<!-- Image Preview -->
										<div v-if="driversLicense.url">
											<b-card overlay :img-src="driversLicense.url" img-alt="Card Image"
												text-variant="white" class="mb-0">
												<b-card-text class="text-right">
													<b-button v-if="selDriver.name === ' - Others - ' ? true : false"
														variant="danger" size="sm" @click="onRemoveDriversLicense()">
														<em class="fa fa-trash"></em>
													</b-button>
												</b-card-text>
											</b-card>
											<b-progress v-if="uploading.uploadStatus === 'uploading'"
												:value="uploading.percentage" :max="uploading.filename" variant="success"
												height="8px" animated />
										</div>
									</b-col>
									<b-col lg="4" md="6" sm="12">
										<b-form-group v-if="selDriver.name === ' - Others - ' ? true : false"
											label="Driver:" label-for="driver">
											<b-form-input name="Driver" type="text" v-model="form.driver.name"
												maxlength="50" v-validate="{
													required: true,
													regex: /^([A-Za-z\s-]{1,50})$/,
												}" />
											<span v-show="errors.has('Driver')" class="help-block">
												{{ errors.first('Driver') }}
											</span>
										</b-form-group>

										<b-form-group label="Assistant/s:" label-for="assistants">
											<b-form-input name="Assistants" type="text" v-model="form.driver.assistants"
												maxlength="50" v-validate="{
													required: false,
													regex: /^([A-Za-z\s-]{1,50})$/,
												}" />
											<span v-show="errors.has('Assistants')" class="help-block">
												{{ errors.first('Assistants') }}
											</span>
										</b-form-group>
									</b-col>
								</b-row>
							</b-card-body>
						</b-collapse>
					</b-card>
					<b-card no-body class="mb-1">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-btn block href="#" v-b-toggle.accordion5 variant="primary"
								class="text-sm-left accordion-title">
								ASSETS
							</b-btn>
						</b-card-header>
						<b-collapse id="accordion5" accordion="my-accordion" role="tabpanel">
							<b-card-body>
								<div>
									<b-row>
										<b-col sm="4" class="mr-2">
											<b-form-group label="Asset Type" label-for="assetType"
												description="The asset type you want to include to in this dispatch">
												<v-select name="assetType" class="style-chooser" label="text"
													:options="allAssetTypesOptions" :reduce="(assetType) => assetType.value"
													v-model="selAssetType">
													<template v-slot:no-options="{ search, searching }">
														<template v-if="searching">
															No results found for
															<em>
																<strong>{{ search }}</strong>
															</em>
														</template>
														<em :style="{ opacity: 0.5 }" v-else>
															Start typing to search for a user
														</em>
													</template>
												</v-select>
											</b-form-group>
										</b-col>
										<b-col lg="3" md="4" sm="4">
											<b-button variant="primary" class="addButton" @click="addAsset">
												Add Asset
											</b-button>
										</b-col>
									</b-row>

									<div>
										<b-row class="mt-4 mb-2">
											<b-col sm="8" class="md-left-text-sm-right">
												<strong>SELECTED ASSETS</strong>
											</b-col>
										</b-row>

										<b-row class="mb-2" v-show="form.assets.length > 0">
											<b-col sm="3" class="text-sm-left">
												<strong>Asset Type</strong>
											</b-col>
											<b-col sm="2" class="text-sm-left">
												<strong>Quantity</strong>
											</b-col>
											<b-col sm="1" class="text-sm-left">
												<strong>Action</strong>
											</b-col>
										</b-row>
										<b-row class="mb-2" v-bind:key="asset.assetTypeId" v-for="asset in form.assets">
											<b-col sm="3" class="text-sm-left">
												{{ asset.assetType }}
											</b-col>
											<b-col sm="2" class="text-sm-left">
												<b-form-group>
													<b-form-input name="quantity" type="number" min="0"
														v-model="selAssetTypes[asset.assetTypeId]" placeholder="0"
														v-validate="'required'" />
													<span v-show="errors.has('quantity')" class="help-block">
														{{ errors.first('quantity') }}
													</span>
												</b-form-group>
											</b-col>
											<b-col sm="1" class="text-sm-left">
												<b-button size="sm" v-b-tooltip.hover.top="'Remove Asset'"
													variant="secondary" @click.stop="removeAsset(asset.assetTypeId)"
													class="mr-1">
													<em class="fa fa-trash"></em>
												</b-button>
											</b-col>
										</b-row>

										<b-row class="mb-2" v-show="form.assets.length === 0">
											<b-col sm="6" class="text-sm-left">
												<em>There is no selected assets yet.</em>
											</b-col>
										</b-row>
									</div>
								</div>
							</b-card-body>
						</b-collapse>
					</b-card>
				</div>
			</b-form>
			<b-row>
				<b-col md="12" sm="12" class="my-1 text-sm-right">
					<b-button variant="secondary" @click="returnToDispatchMain">
						Cancel
					</b-button>
					&nbsp;&nbsp;
					<b-button variant="primary" @click="handleOk" :disabled="isLoading">
						Save
					</b-button>
				</b-col>
				<b-col md="1" sm="1" class="my-1"></b-col>
			</b-row>
		</b-card>
	</div>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { ValidationUtil } from '@/utils/validationUtil';
import { DispatchUtil } from '@/utils/dispatchUtil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { LocationUtil } from '@/utils/locationUtil';

// API
import dispatchApi from '@/api/dispatchApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import { storage } from '@/config/firebase';
import _ from 'lodash';

export default {
	name: 'edit-dispatch',
	components: {
		Loading,
		DateRangePicker,
	},
	data() {
		return {
			form: {
				id: '',
				dispatchId: '',
				source: {},
				destination: {},
				transportation: {
					plateNo: '',
					company: '',
					companyId: '',
					pickUpPoint: null,
					datePickUp: 0,
					dropOffPoint: null,
					dateDropOff: 0,
					estimatedTravelTime: 0,
					actualTravelTime: 0,
					hasCheckingInterval: false,
					checkingInterval: 0,
				},
				driver: {
					userId: '',
					name: '',
					company: '',
					companyId: '',
					assistants: '',
					isAccountable: false,
					license: {}
				},
				newTransportationToAdd: {},
				assets: [],
				status: 'Draft',
				notes: '',
				fromInactiveNode: 'false',
				toInactiveNode: 'false',
				creationSource: '',
			},

			showFirstPane: true,

			driversLicense: {},
			uploading: {
				filename: '',
				percentage: 0,
				uploadStatus: '', // [uploading, success, error]
			},

			sourceCompanyOptions: [],
			destinationCompanyOptions: [],
			sourceLocationOptions: [],
			destinationLocationOptions: [],

			selSourceCompany: config.companyDefaultValue,
			selDestinationCompany: config.companyDefaultValue,
			selSourceLocation: config.storageLocationDefaultValue,
			selDestinationLocation: config.storageLocationDefaultValue,
			selTransportation: config.transportationDefaultValue,
			selAssetType: config.assetTypeDefaultValue,
			selDriver: config.driverDefaultValue,
			selAssetTypes: [],
			selDispatch: {},

			// Params from Dispatch
			params: {},
			allTransportationsOptions: [],
			allDriverOptions: [],
			allAssetTypesOptions: [],

			allCompaniesObj: {},
			allStorageLocationsObj: {},
			allConnectionsObj: {},
			allDriversObj: {},
			allTransportationsObj: {},
			allAssetTypesObj: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			loggedUser: this.$store.getters.loggedUser,

			// Check for loader
			isLoading: false,
		};
	},
	watch: {
		allCompaniesObj: function (newVal, oldVal) {
			this.sourceCompanyOptions = DropDownItemsUtil.retrieveCompanyItems(
				this.allCompaniesObj, this.loggedUserCompany
			);
		},

		selSourceCompany: function (newVal, oldVal) {
			this.onChangeSourceCompany();
		},

		selDestinationCompany: function (newVal, oldVal) {
			this.onChangeDestinationCompany();
		},

		selTransportation: function (newVal, oldVal) {
			this.form.newTransportationToAdd = {};
			if (newVal.name === ' - Others - ') {
				this.form.newTransportationToAdd = {
					company: '', // required
					companyId: '',
					hasCheckingInterval: false,
					checkingInterval: 0,
					createdBy: this.loggedUser.emailAddress,
					dateCreated: DateUtil.getCurrentTimestamp(),
					dateUpdated: null,
					description: null,
					id: null,
					isActive: 'true',
					plateNo: '', // required
					updatedBy: null,
				};

				this.form.transportation.hasCheckingInterval = false;
				this.form.transportation.checkingInterval = 0;
			} else {
				// Set pre define checking interval values
				this.form.transportation.hasCheckingInterval = newVal.hasCheckingInterval ? newVal.hasCheckingInterval : false;
				this.form.transportation.checkingInterval = newVal.checkingInterval ? parseInt(newVal.checkingInterval) : 0;
			}
		},

		selDriver: function (newVal, oldVal) {
			if (newVal.id) {
				this.driversLicense = newVal.license;
			}
		}
	},
	mounted() {
		// Don't initiate data retrieval when the account is not authenticated
		if (!this.$store.getters.isAuthenticated) {
			return;
		}

		this.getCurrentLocation();

		// show loading indicator
		this.isLoading = true;

		setTimeout(() => {
			// init parameters
			this.params = this.$store.getters.dispatchParams;

			// Objects
			this.allCompaniesObj = this.params.allCompaniesObj;
			this.allStorageLocationsObj = this.params.allStorageLocationsObj;
			this.allConnectionsObj = this.params.allConnectionsObj;
			this.allTransportationsObj = this.params.allTransportationsObj;
			this.allAssetTypesObj = this.params.allAssetTypesObj;
			this.allDriversObj = this.$store.getters.driverUsers;
			this.selDispatch = this.$store.getters.currDispatch;

			// reset options
			this.sourceCompanyOptions = DropDownItemsUtil.retrieveCompanyItems(
				this.allCompaniesObj, this.loggedUserCompany
			);
			this.allTransportationsOptions =
				DropDownItemsUtil.retrieveTransportationItems(
					this.allTransportationsObj
				);
			this.allDriverOptions = DropDownItemsUtil.retrieveActiveDriverItems(
				this.allDriversObj
			);
			this.allAssetTypesOptions = DropDownItemsUtil.retrieveAssetTypeItems(
				this.allAssetTypesObj
			);

			this.onReset();
		}, config.timeout);

		// hide loading indicator
		this.isLoading = false;
	},
	methods: {
		async getCurrentLocation() {
			try {
				this.currLocation = await this.$getCurrentLocation();
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}
		},
		resetSourceStorageLocationOptions() {
			this.sourceLocationOptions = [];
			this.sourceLocationOptions.push({
				value: config.storageLocationDefaultValue,
				text: ' - Please select - ',
			});

			this.selSourceLocation = config.storageLocationDefaultValue;
		},
		resetDestinationCompanyOptions() {
			this.destinationCompanyOptions = [];
			this.destinationCompanyOptions.push({
				value: config.companyDefaultValue,
				text: ' - Please select - ',
			});

			this.selDestinationCompany = config.companyDefaultValue;
		},
		resetDestinationStorageLocationOptions() {
			this.destinationLocationOptions = [];
			this.destinationLocationOptions.push({
				value: config.storageLocationDefaultValue,
				text: ' - Please select - ',
			});

			this.selDestinationLocation = config.storageLocationDefaultValue;
		},

		onChangeSourceCompany() {
			if (this.selSourceCompany !== config.companyDefaultValue) {
				// retrieve the source storage locations
				this.sourceLocationOptions =
					DropDownItemsUtil.retrieveStorageLocationOfConnectionItems(
						this.allConnectionsObj,
						this.allStorageLocationsObj,
						this.selSourceCompany.id,
						this.selSourceCompany.id
					);

				// pre-select the single option value
				if (this.sourceLocationOptions.length == 2) {
					this.selSourceLocation = this.sourceLocationOptions[1].value;
				}

				let isInactiveCompany = DispatchUtil.isInactiveCompany(
					this.allCompaniesObj[this.selSourceCompany.id]
				);

				// Update dispatch form
				this.form.fromInactiveNode = isInactiveCompany;

				if (isInactiveCompany === 'true') {
					// retrieve the connected destination companies
					this.destinationCompanyOptions =
						DropDownItemsUtil.retrieveConnectedCompanyItems(
							{},
							this.loggedUserCompany
						);
				} else {
					// retrieve the connected destination companies
					this.destinationCompanyOptions =
						DropDownItemsUtil.retrieveConnectedCompanyItems(
							this.allConnectionsObj,
							this.selSourceCompany
						);
				}
			} else {
				// reset all source and destination fields
				this.resetSourceStorageLocationOptions();
				this.resetDestinationCompanyOptions();
				this.resetDestinationStorageLocationOptions();
			}
		},
		onChangeDestinationCompany() {
			if (this.selDestinationCompany !== config.companyDefaultValue) {
				if (this.selSourceCompany.isActive !== 'true') {
					this.destinationLocationOptions =
						DropDownItemsUtil.retrieveStorageLocationOfCurrCompany(
							this.selDestinationCompany.id,
							this.allStorageLocationsObj
						);
				} else {
					this.destinationLocationOptions =
						DropDownItemsUtil.retrieveStorageLocationOfConnectionItems(
							this.allConnectionsObj,
							this.allStorageLocationsObj,
							this.selSourceCompany.id,
							this.selDestinationCompany.id
						);
				}

				// pre-select the single option value
				if (this.destinationLocationOptions.length == 2) {
					this.selDestinationLocation =
						this.destinationLocationOptions[1].value;
				}

				this.form.toInactiveNode = DispatchUtil.isInactiveCompany(
					this.allCompaniesObj[this.selDestinationCompany.id]
				);
			} else {
				// retrieve the destination storage locations
				this.resetDestinationStorageLocationOptions();
			}
		},

		plateNoExists(transportation) {
			let currPlateNo = '';

			let currDispatch = this.$store.getters.currDispatch;
			if (
				!_.isEmpty(currDispatch) &&
				currDispatch.transportation !== null &&
				currDispatch.transportation.plateNo !== null
			) {
				currPlateNo = currDispatch.transportation.plateNo;
			}

			const plateNos = _.map(this.allTransportationsObj, (o) => {
				let plateNo = ""
				if (o.plateNo && !_.isEmpty(o.plateNo)) {
					plateNo = o.plateNo.toUpperCase();
				}
				return plateNo;
			});

			return (
				plateNos.includes(transportation.plateNo.toUpperCase()) &&
				currPlateNo != transportation.plateNo
			);
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();
			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input');
				this.isLoading = false;
				return;
			}

			// source and destination must not be the same
			if (
				this.selSourceCompany.id === this.selDestinationCompany.id &&
				this.selSourceLocation.id === this.selDestinationLocation.id
			) {
				this.$toaster.warning('Source and Destination should not be the same');
				this.isLoading = false;
				return;
			}

			// for new transportation created on-the-fly
			if (this.selTransportation.name === ' - Others - ') {
				if (
					!DispatchUtil.isValidTransportation(this.form.newTransportationToAdd)
				) {
					this.$toaster.warning('Company or Plate Number is required.');
					this.isLoading = false;
					return;
				}
				if (this.plateNoExists(this.form.transportation)) {
					this.$toaster.warning('Plate number already exists.');
					this.isLoading = false;
					return;
				}
			}

			this.form = this.processDispatchForm();
			await this.handleSubmit();
		},

		processDispatchForm() {
			// Update Dispatch Fields
			this.form.dateUpdated = DateUtil.getCurrentTimestamp();
			this.form.updatedBy = this.loggedUser.id;

			// Removes excess whitespace
			this.form.notes = ValidationUtil.removeExcessWhiteSpace(this.form.notes);

			this.updateDriver();
			this.updateSourceSelection();
			this.updateDestinationSelection();
			this.updateTransportation();
			this.updateAssets();

			return this.form;
		},
		updateDriver() {

			if (this.selDriver.id) {
				this.form.driver.userId = this.selDriver.id;
				this.form.driver.name = this.selDriver.name;
				this.form.driver.company = this.selDriver.company;
				this.form.driver.companyId = this.selDriver.companyId;
				this.form.driver.license = this.selDriver.license;
			} else {

				this.form.driver.name = ValidationUtil.removeExcessWhiteSpace(
					this.form.driver.name
				);

				this.driversLicense.name = `DL_${this.form.dispatchId}_${this.form.driver.name}.jpg`;

				this.form.driver.license.url = this.driversLicense.url;
				this.form.driver.license.name = this.driversLicense.name;
				this.form.driver.license.longitude = this.currLocation ? this.currLocation.lng : 0;
				this.form.driver.license.latitude = this.currLocation ? this.currLocation.lat : 0;
			}

			this.form.driver.assistants = ValidationUtil.removeExcessWhiteSpace(
				this.form.driver.assistants
			);
		},
		updateSourceSelection() {
			this.form.source = {
				company: this.selSourceCompany.name,
				companyId: this.selSourceCompany.id,
				storageLocation: this.selSourceLocation.name,
				storageLocationId: this.selSourceLocation.id,
				geoaddress: LocationUtil.getGeoaddress(
					this.selSourceLocation.geoaddress
				),
				latitude: LocationUtil.getLatitude(this.selSourceLocation.geoaddress),
				longitude: LocationUtil.getLongitude(this.selSourceLocation.geoaddress),
			};
		},
		updateDestinationSelection() {
			this.form.destination = {
				company: this.selDestinationCompany.name,
				companyId: this.selDestinationCompany.id,
				storageLocation: this.selDestinationLocation.name,
				storageLocationId: this.selDestinationLocation.id,
				geoaddress: LocationUtil.getGeoaddress(
					this.selDestinationLocation.geoaddress
				),
				latitude: LocationUtil.getLatitude(
					this.selDestinationLocation.geoaddress
				),
				longitude: LocationUtil.getLongitude(
					this.selDestinationLocation.geoaddress
				),
			};
		},

		updateTransportation() {

			if (!_.isEmpty(this.form.newTransportationToAdd)) {
				this.form.transportation.plateNo = this.form.newTransportationToAdd.plateNo;
				this.form.transportation.company = this.form.newTransportationToAdd.company;
				this.form.transportation.companyId = this.form.newTransportationToAdd.companyId;
			} else {
				this.form.transportation.plateNo = this.selTransportation.name;
				this.form.transportation.company = this.selTransportation.company;
				this.form.transportation.companyId = this.selTransportation.companyId;
			}
		},
		updateAssets() {
			for (const asset of this.form.assets) {
				asset.expectedQuantity = parseInt(
					this.selAssetTypes[asset.assetTypeId]
				);
			}
		},

		async uploadDriversLicensePhoto(dispatch) {
			let image = this.driversLicense;
			if (image._isNew) {
				const uploadResult = await this.firebaseUploadImage(image, dispatch);
				if (uploadResult.name && uploadResult.url) {
					dispatch.driver.license.url = uploadResult.url;
					const updateResult = await dispatchApi.saveDispatch(
						dispatch,
						this.loggedUser.id
					);
					if (updateResult.isSuccess) {
						this.onUploadingInProgress('success');
					}
				}
			}
		},
		async firebaseUploadImage(mediaFile, dispatch) {

			this.onUploadingInProgress('uploading', mediaFile.name, 0);

			return new Promise((resolve, reject) => {
				let storageRef = storage.ref(`${mediaFile.fbStoragePath}/${mediaFile.name}`);
				let task = storageRef.put(mediaFile.file);

				task.on(
					'state_changed',
					(snapshot) => {
						let percentage =
							(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						this.onUploadingInProgress('uploading', mediaFile.name, percentage);
					},
					(error) => {
						reject(error);
						this.onResetUploadingState();
					},
					() => {
						task.snapshot.ref.getDownloadURL().then((downloadURL) => {
							resolve({
								name: mediaFile.name,
								url: downloadURL,
							});
						});
					}
				);
			});
		},
		onUploadingInProgress(uploadStatus, filename = '', percentage = 0) {
			this.uploading.filename = filename;
			this.uploading.percentage = percentage;
			this.uploading.uploadStatus = uploadStatus;
		},
		onResetUploadingState() {
			this.uploading = {
				filename: '',
				percentage: 0,
				uploadStatus: '',
			};
		},
		onRemoveDriversLicense(index) {
			this.driversLicense = {};
			this.$refs['dirvers-lincense-file-input'].reset();
		},
		onSelectDriversLicense(evt) {
			const vm = this;
			const file = evt.target.files[0];
			const url = URL.createObjectURL(file);

			let dimensions = { w: 0, h: 0 };

			const image = new Image();
			image.onload = function () {
				dimensions.w = image.width;
				dimensions.h = image.height;

				if (dimensions.w > 1000 || dimensions.h > 1000) {
					vm.$toaster.warning(
						"Drivers lincense photo's width and height shouldn't be greater than 1000 pixels"
					);
				} else {
					vm.driversLicense = {
						url: url,
						file: file,
						fbStoragePath: 'images/driversLicenses',
						_isNew: true,
					};
				}
			};
			image.src = url;
		},

		async handleSubmit() {
			let dispatchId = this.form.dispatchId;

			try {
				let { data } = await dispatchApi.saveDispatch(this.form, this.loggedUser.id);

				if (data.isSuccess) {
					// upload driverse license photo
					if (!_.isEmpty(this.driversLicense)) {
						await this.uploadDriversLicensePhoto(data.dispatch);
					}

					this.$toaster.success(
						`Dispatch "${dispatchId}" was updated successfully.`
					);
					EventBus.$emit('onCloseEditDispatch', data.dispatch);
					this.addNewTransportationToStore(data.dispatch);

					// reset the state and revert to dispatch page
					this.returnToDispatchMain();
				} else {
					this.$toaster.error(data.message);
				}

			} catch (error) {
				this.$toaster.error(
					`Error updating dispatch ${dispatchId}. Please try again.`
				);
			}

			// hide loading indicator
			this.isLoading = false;
		},
		addNewTransportationToStore(dispatch) {
			if (dispatch.newTransportationToAdd && !_.isEmpty(dispatch.newTransportationToAdd)) {
				let newTransportations = {};
				newTransportations[dispatch.newTransportationToAdd.id] =
					dispatch.newTransportationToAdd;
				this.$store.dispatch('updateAllTransportations', newTransportations);
			}

			if (dispatch.newCompanyToAdd && !_.isEmpty(dispatch.newCompanyToAdd)) {
				let newCompanies = {};
				newCompanies[dispatch.newCompanyToAdd.id] = dispatch.newCompanyToAdd;
				this.$store.dispatch('updateAllCompanies', newCompanies);
			}
		},
		returnToDispatchMain() {
			this.params.fromEditDispatch = true;

			this.$store.dispatch('setDispatchParams', this.params);
			this.$store.dispatch('setCurrentDispatch', {});

			if (!this.isSuperAdmin) {
				this.$router.push({ path: '/dispatch' });
			} else {
				this.$router.push({ path: '/admin/admin-dispatch' });
			}
		},

		addAsset() {
			if (this.selAssetType.id === null) {
				this.$toaster.warning('Please select a asset type to add.');
				return;
			}
			if (
				DispatchUtil.isExistingAssetType(this.form.assets, this.selAssetType.id)
			) {
				let assetTypeName = this.selAssetType.name;
				this.$toaster.warning(`Asset type "${assetTypeName}" already added.`);
				return;
			}

			this.form.assets.push({
				assetType: this.selAssetType.name,
				assetTypeId: this.selAssetType.id,
				expectedQuantity: 0,
				actualQuantity: 0,
			});
		},
		removeAsset(assetTypeId) {
			for (let i = 0; i < this.form.assets.length; i++) {
				let asset = this.form.assets[i];
				if (asset.assetTypeId === assetTypeId) {
					this.form.assets.splice(i, 1);
					delete this.selAssetTypes[assetTypeId];
				}
			}
		},

		onReset() {
			/* Reset our form values */
			let dispatch = this.selDispatch;

			// primary details
			this.form.id = dispatch.id;
			this.form.dispatchId = dispatch.dispatchId;
			this.form.source = dispatch.source;
			this.form.destination = dispatch.destination;
			this.form.status = dispatch.status;
			this.form.notes = dispatch.notes;

			// transportation
			this.form.transportation = dispatch.transportation;
			this.form.driver = dispatch.driver;
			this.form.newTransportationToAdd = dispatch.newTransportationToAdd;
			this.driversLicense = DispatchUtil.getDriverLicenseObj(this.form.driver);

			// assets
			this.form.assets = dispatch.assets;
			for (const asset of dispatch.assets) {
				this.selAssetTypes[asset.assetTypeId] = asset.expectedQuantity;
			}

			// timestamps
			this.form.dateCreated = dispatch.dateCreated;
			this.form.createdBy = dispatch.createdBy;
			this.form.dateActivated = dispatch.dateActivated;
			this.form.activatedBy = dispatch.activatedBy;
			this.form.dateUpdated = dispatch.dateUpdated;
			this.form.updatedBy = dispatch.updatedBy;
			this.form.dateDeployed = dispatch.dateDeployed;
			this.form.deployedBy = dispatch.deployedBy;
			this.form.dateCancelled = dispatch.dateCancelled;
			this.form.cancelledBy = dispatch.cancelledBy;
			this.form.dateReceived = dispatch.dateReceived;
			this.form.receivedBy = dispatch.receivedBy;

			// others
			this.form.creationSource = dispatch.creationSource;
			this.form.fromInactiveNode = dispatch.fromInactiveNode;
			this.form.toInactiveNode = dispatch.toInactiveNode;

			if (dispatch.validationWarnings) {
				this.form.validationWarnings = dispatch.validationWarnings;
			}

			this.onResetDropDownValues(dispatch);
			this.onResetUploadingState();

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},
		onResetDropDownValues(dispatch) {
			// SOURCE COMPANY
			let source = dispatch.source;
			let sourceCompany = this.allCompaniesObj[source.companyId];
			this.selSourceCompany = DropDownItemsUtil.getCompanyItem(sourceCompany);

			// SOURCE LOCATION
			let sourceLoc = this.allStorageLocationsObj[source.storageLocationId];
			this.selSourceLocation =
				DropDownItemsUtil.getStorageLocationItem(sourceLoc);

			// DESTINATION COMPANY
			let destination = dispatch.destination;
			let destinationCompany = this.allCompaniesObj[destination.companyId];
			this.selDestinationCompany =
				DropDownItemsUtil.getCompanyItem(destinationCompany);

			// DESTINATION LOCATION
			let destiLoc = this.allStorageLocationsObj[destination.storageLocationId];
			this.selDestinationLocation =
				DropDownItemsUtil.getStorageLocationItem(destiLoc);

			// DRIVER
			if (dispatch.driver.userId) {
				this.selDriver = DropDownItemsUtil.getDriverItem(
					dispatch.driver
				);
			} else {
				this.selDriver = config.driverOtherValue;
			}

			// TRANSPORTATION
			this.selTransportation = DropDownItemsUtil.getTransportationItem(
				dispatch.transportation
			);
		},
	},
};
</script>

<style scoped>
.accordion-title {
	color: white !important;
}
</style>
